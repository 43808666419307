<template>
  <div class="contaier">
    <div class="filter-list">
      <div class="filter-item">
        <div class="filter-key" style="float: left">服务分类：</div>
        <div class="filter-values" :class="{ filtercollapse: whether }">
          <ul class="clear">
            <li
              :class="{ Active: Active == item.name }"
              v-for="(item, index) in type"
              @click="jie_gou(index, item)"
              :key="index"
            >
              <a onclick="return false;">{{ item.name }}</a>
            </li>
          </ul>
        </div>
        <!-- <div class="filter-options" style="visibility: visible;">
                <a @click="addclass" href="javascript:;" class="j-filter-more filter-more">{{name}}</a>
            </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { industry } from "../../views/common/js/api";
import { eType } from "../../common/js/api";
export default {
  name: "shai_xuan",
  data() {
    return {
      name: "展开全部",
      jie: "",
      whether: true,
      2: "文案一",
      structureLists: [],
      structureList: [],
      type: [],
      wen_an: [],
    };
  },
  created() {
    this.hang_ye();
    this.getType();
  },
  methods: {
    getType() {
      eType()
        .then((res) => {
          console.log(res);
          this.type = res.data;
        })
        .catch(() => {});
    },
    addclass() {
      if (this.whether == true) {
        this.whether = false;
        this.name = "收起";
      } else {
        this.whether = true;
        this.name = "展开全部";
      }
    },
    hang_ye() {
      industry({})
        .then((res) => {
          console.log(res);
          this.structureLists = res.data;
          for (var i = 0; i < this.structureLists.length; i++) {
            var obj = Object.assign(this.structureLists[i], {
              isSelected: false,
            });
            this.structureList.push(obj);
          }
        })
        .catch((err) => {});
    },
    jie_gou(index, item) {
      // 商标结构
      this.Active = item.name;
      this.jie = item.name;
      if (item.name === "不限结构") {
        this.jie = "";
      }
      //子组件
      // this.$emit('name',item.name)
      this.$parent.fatherMethod(item.id);
    },
  },
};
</script>

<style scoped lang="less">
/*点击后样式*/
.bottom_con {
  margin: 0 auto;
  width: 804px;
  height: auto;
  background-color: rgba(247, 247, 247, 100);
  text-align: center;
  border: 1px solid rgba(255, 255, 255, 100);
  .nei_box {
    display: flex;
    width: 804px;
    height: auto;
    background-color: rgba(247, 247, 247, 100);
    padding: 15px;
    /*justify-content: space-between;*/
    flex-wrap: wrap;
    overflow: hidden;
    .nei_text {
      margin-top: 15px;
      width: 100px;
    }
  }
}
.clear a {
  color: rgba(105, 105, 105, 100);
  font-size: 16px;
}
.clear {
  margin: 0 auto;
  width: 804px;
}

.filter-list {
  position: relative;
  width: 100%;
  font-size: 14px;
  padding: 5px 0px;
}
.contaier {
  margin: 30px 50px;
  .filter-item {
    padding-top: 15px;
    position: relative;
    .filter-key {
      /*float: left;*/
      width: 56px;
      padding-left: 30px;
      padding-right: 70px;
      white-space: nowrap;
      margin-top: 15px;
      height: 20px;
      //   line-height: 20px;
      color: #00050a;
      cursor: default;
    }
    .filter-values {
      margin-left: 116px;
      margin-right: 110px;
      /*border-bottom: 1px #f0f0f0 dashed;*/
      color: #333;
      transition: all 0.3s;
    }
    .filtercollapse {
      height: 35px;
      overflow: hidden;
    }
    .filter-options {
      position: absolute;
      right: 30px;
      top: 14px;
      visibility: hidden;
      .filter-more {
        padding-right: 16px;
        color: #999;
        line-height: 16px;
        display: inline-block;
      }
    }
  }
}
.filter-list .Active a {
  padding: 5px 10px;
  color: #ffffff;
  background: #3892eb;
  border-radius: 5px;
  /*color: #00bb00;*/
}
.filter-list li {
  float: left;
  margin: 15px 40px 15px 0px;
  height: 20px;
  // line-height: 20px;
  cursor: pointer;
}
.cf:after,
.clear:after {
  content: "";
  display: block;
  height: 0;
  visibility: hidden;
  clear: both;
}
</style>