<template>
    <div class="container" v-if="arr.length!=0">
        <div class="li_box">
            <div class="one" @click="fn(item.id)" v-for="(item,index) in arr " :key="index">
                <div class="img_box" v-if="item.img==null">
                    <img src="../../../assets/img/home/zhan_wei.png" />
                </div>
                <div v-else class="img_box" >
                   <img :src="item.img|ImgFilter"/>
                </div>
                <div class="two">
                    <!--<div  class="price"><span>{{}}</span></div>-->
                    <div class="title">
                        <span>{{item.title}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container" v-else>
        <div class="li_box">
            <div class="img_box">
                <div>
                    <img src="../../../assets/img/home/wu.png" />
                </div>
                <div>暂无内容</div>
            </div>
        </div>
    </div>

</template>

<script>
    import {employer_organ} from "../../common/js/api";
    export default {
        name: "zong",
        props:[],
        data(){
            return {
                total: 0,
                pageSize: 6,
                pageNum: 1,
               flag:2,
              arr:[]
            }
        },
        created(){
        },
        methods:{
            type_fei(par,hang,pageS,pageN){
                employer_organ({
                    // industry:hang,  //行业领域
                    keywords:par,
                    views:"", //浏览量
                    type:hang, //108高校，109科研院所，110其他科研组织，111创新个人
                    page: pageN,
                    limits:pageS,
                    ids:1  //1是綜合
                }).then((res) => {
                    this.arr=res.data.data
                    this.total=res.data.total
                    this.$emit('total',this.total)
                }).catch((err) => {
                });
            },
            fn(e){
                this.$router.push({
                    path:'/GaoDetail',
                    query:{
                        ids:e
                    }
                })
            }
        },
        filters:{
            ImgFilter(value){
                return 'http://hout.kehui.cloud/' + value
            }
        }
    }
</script>

<style scoped  lang="less">
    .li_box .one:hover{
        border: 1px solid #eee;
        box-sizing: border-box;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,.12), 0 0 6px 0 rgba(0,0,0,.04);
    }
   .container{
       width: 1230px;
       /*margin: 0 auto;*/
       box-sizing: border-box;

       .li_box{
           /*margin: 0 auto;*/
           margin-left: 0px;
           width: 1230px;
           display: flex;
           flex-wrap: wrap;
           .img_box{
               margin: 0 auto;
               text-align: center;
               color: rgba(105, 105, 105, 100);
               font-size: 20px;
               img{
                   width: 530px;
                   height: 404px;
               }
           }
           .one{
               cursor: pointer;
               text-align: center;
               width: 380px;
               height: 320px;
               line-height: 20px;
               border-radius: 5px;
               .two{
                   width: 348px;
                   .price{
                       text-align: center;
                       color: rgba(255, 7, 7, 100);
                       font-size: 18px;
                       width: 43px;
                       height: 27px;
                       padding: 10px 0px;
                       margin: 0 auto 10px auto;
                   }
                   .title{
                       margin-top: 20px;
                       color: rgba(16, 16, 16, 100);
                       font-size: 18px;
                   }
               }
               .img_box{
                   text-align: center;
                   width: 348px;
                   height: 214px;
                   margin:10px auto;
                     img{
                         margin: 0 auto;
                         text-align: center;
                         width: 348px;
                         height: 214px;
                     }
               }

           }

       }
   }

</style>