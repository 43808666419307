<template>
    <div class="big_container">
        <div class="box">
            <div class="box_one">
                <div class="title_flex">
                    <div class="line"></div>
                    <div class="text">科研院所</div>
                    <div class="line"></div>
                </div>
            </div>
        </div>
        <div class="botom_box">
            <!--蓝色-->
            <div class="top_box">
                <p>机构分类</p>
            </div>
            <div class="search_input">
                <el-input
                        placeholder="请输入内容"
                        v-model="input4">
                    <i slot="prefix" class="el-input__icon el-icon-search"></i>
                </el-input>
                <div>
                    <span>搜索</span>
                </div>
            </div>
            <!--筛选组件-->
            <shai></shai>
            <!--tab-->
            <div class="tab_box">
                <el-tabs v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane label="综合" name="first"><zong></zong></el-tab-pane>
                    <el-tab-pane label="热门优选" name="second">热门优选</el-tab-pane>
                </el-tabs>
            </div>

        </div>
    </div>
</template>

<script>
    import shai from "../../../components/common/shai_xuan"
    import zong from "../common/zong"
    export default {
        name: "scientific",
        components:{
            shai,
            zong
        },
        data() {
            return {
                activeName: 'first'
            };
        },
        methods: {
            handleClick(tab, event) {
                console.log(tab, event);
            }
        }
    }
</script>

<style scoped lang="less">
    .tab_box{
        overflow: hidden;
        margin: 30px;
    }
    /deep/ .el-tabs__nav-wrap{
        overflow: hidden;
        padding-left: 30px;
        width: 1154px;
        height: 64px;
        line-height: 64px;
        border-radius: 5px;
        background-color: rgba(247, 247, 247, 100);
        text-align: center;
        border: 1px solid rgba(255, 255, 255, 100);
    }
    /deep/ .el-input{
        width: 976px;
        border-radius: 25px;
    }
    .big_container{
        background-color: #F8F9FA;
        width: 100%;
        height: auto;
    }
    .botom_box{
        width: 1200px;
        height: auto;
        background-color: #fff;
        margin: 0 auto;
        .search_input{
            margin: 30px 80px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            span{
                color: rgba(0, 132, 255, 100);
                font-size: 14px;
                text-align: center;
            }
        }
        .top_box{
            width: 1200px;
            height: 40px;
            line-height: 20px;
            border-radius: 5px 5px 0px 0px;
            background-color: rgba(56, 146, 235, 100);
            text-align: center;
            border: 1px solid rgba(255, 255, 255, 100);
            p{
                line-height: 40px;
                color: rgba(248, 249, 250, 100);
                font-size: 20px
            }
        }
    }
    .box {
        width: 100%;
        margin: 0 auto;
        .box_one {

        }
        .title_flex {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            .text {
                font-size: 28px;
                color: #3892EB;
                font-weight: 600;

            }
            .line {
                margin: 100px;
                width: 60px;
                height: 1px;
                background-color: #3892EB;
            }
        }
    }
</style>